/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import zxcvbn from 'zxcvbn';
import LoadingModal from './LoadingModal';
import './RegisterLoginPage.css';
import registerLoginImage from '../assets/images/registerLoginImage.svg';
import visibleImg from '../assets/images/visible.svg';
import invisibleImg from '../assets/images/invisible.svg';

const RegisterLoginPage = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFaCode, setTwoFaCode] = useState(''); // New state for 2FA Code input during login
  const [username, setUsername] = useState('');
  const [wallet, setWallet] = useState('');
  const [currency, setCurrency] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [passwordStrength, setPasswordStrength] = useState(0); // State for password strength
  const [twoFaMethod, setTwoFaMethod] = useState('none'); // New state for 2FA method
  const [qrCode, setQrCode] = useState(null); // New state for QR Code
  const [twoFaSecret, setTwoFaSecret] = useState(''); // New state for 2FA Secret
  const [twoFaCodeSent, setTwoFaCodeSent] = useState(false); // State to track if 2FA code is sent
  const [showTwoFaInput, setShowTwoFaInput] = useState(false); // State to manage visibility of 2FA input
  const [is2FAChecked, setIs2FAChecked] = useState(false); // State to control when to show the 2FA input based on user interaction
  const [is2FAModalOpen, setIs2FAModalOpen] = useState(false); // New state to manage 2FA modal
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('/api/supported-currencies');
        const supportedCurrencies = response.data.supportedCurrencies.map(curr => ({ value: curr, label: curr.toUpperCase() }));
        setCurrencies(supportedCurrencies);
        if (supportedCurrencies.length > 0) {
          setCurrency(supportedCurrencies[0]);
        }
      } catch (err) {
        setError('Failed to load supported currencies');
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(() => {
    const result = zxcvbn(password);
    setPasswordStrength(result.score);
  }, [password]);

  const handleInitialLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    gtag('event', 'login', {
      'event_category': 'User',
      'event_label': 'Login_Started',
      'value': 1
    });


    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.data.requires2FA) {
        setTwoFaMethod(response.data.twoFaMethod);
        setIs2FAChecked(true);
        setIs2FAModalOpen(true); // Open 2FA modal
        setIsLoading(false);
      } else {
        localStorage.setItem('token', response.data.accessToken);

        gtag('event', 'login', {
          'event_category': 'User',
          'event_label': 'Login_Completed',
          'value': 1
        });
        setIsLoading(false);
        setError('');
        setSuccessMessage('Login successful');
        onLogin(response.data);
      }
    } catch (err) {
      setIsLoading(false);
      gtag('event', 'login', {
        'event_category': 'User',
        'event_label': 'Login_Failed',
        'value': 1,
        'event_action': err.response?.data || 'Login Failure',
      });
      if (err.response && err.response.status === 429) {
        // Handle rate limiting error
        const retryAfter = err.response.headers['retry-after'];
        const retryAfterMinutes = Math.ceil(retryAfter / 60);
        setSuccessMessage('');
        setError(`Too many login attempts. Please try again after ${retryAfterMinutes} minutes.`);
      } else {
        setIsLoading(false);
        setSuccessMessage('');
        setError(err.response?.data || 'Invalid email or password');
      }
    }
  };

  const handleVerify2FALogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('/api/verify-2fa-login', { email, twoFaCode });
      localStorage.setItem('token', response.data.accessToken);

      gtag('event', 'login', {
        'event_category': 'User',
        'event_label': 'Login_Completed',
        'value': 1
      });
      setIsLoading(false);
      setError(''); // Clear any error message
      setSuccessMessage('2FA verification successful!');
      onLogin(response.data);
      setIs2FAModalOpen(false); // Close 2FA modal after successful verification
    } catch (err) {
      setIsLoading(false);
      gtag('event', 'login', {
        'event_category': 'User',
        'event_label': 'Login_Failed',
        'value': 1,
        'event_action': err.response?.data || 'Login Failure',
      });
      if (err.response && err.response.status === 429) {
        setIsLoading(false);
        // Handle rate limiting error
        const retryAfter = err.response.headers['retry-after'];
        const retryAfterMinutes = Math.ceil(retryAfter / 60);
        setSuccessMessage('');
        setError(`Too many 2FA attempts. Please try again after ${retryAfterMinutes} minutes.`);
      } else {
        setIsLoading(false);
        setSuccessMessage('');
        setError(err.response?.data || 'Invalid 2FA code');
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    gtag('event', 'register', {
      'event_category': 'User',
      'event_label': 'Registration_Started',
      'value': 1
    });

    // Client-side validation
    if (!username || !email || !password || !wallet || !currency) {
      setError('All fields are required');
      setSuccessMessage(''); // Clear any success message
      return;
    }

    // Check password strength
    if (passwordStrength < 2) { // Set minimum strength level (0-4)
      setError('Password is too weak');
      setSuccessMessage(''); // Clear any success message
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      setSuccessMessage(''); // Clear any success message
      return;
    }

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setError('Invalid email format');
      setSuccessMessage(''); // Clear any success message
      return;
    }

    setIsLoading(true);

    try {
      const payload = { wallet: wallet, currency: currency.value };
      const validateResponse = await axios.post('/api/validate-wallet', payload);
      if (validateResponse.data !== 'OK') {
        setError('Invalid wallet address');
        setSuccessMessage(''); // Clear any success message
        setIsLoading(false);
        return;
      }

      const registrationPayload = {
        username,
        email,
        password,
        wallet,
        currency: currency.value,
        twoFaMethod, // Include 2FA method in the registration payload
      };

      const response = await axios.post('/api/register', registrationPayload);

      if (twoFaMethod === 'app' && response.data.qrCode) {
        setQrCode(response.data.qrCode);
        setTwoFaSecret(response.data.twoFaSecret);
        setIsLoading(false);
      } else if (twoFaMethod === 'email') {
        setTwoFaSecret(response.data.twoFaSecret);
        setTwoFaCodeSent(true); // Set the state to show the input for the code
        setError('Check your email for the 2FA code');
        setIsLoading(false);
      } else {
        localStorage.setItem('token', response.data.accessToken); // Ensure the token is stored correctly
        gtag('event', 'register', {
          'event_category': 'User',
          'event_label': 'Registration_Completed',
          'value': 1
        });
        onLogin(response.data);
      }
      setError(''); // Clear any error message
      setSuccessMessage('Registration successful!');
      setIsLoading(false);
    } catch (err) {
      gtag('event', 'register', {
        'event_category': 'User',
        'event_label': 'Registration_Error',
        'value': 1,
        'event_action': err.response?.data || 'Registration failed'
      });
      setSuccessMessage('');
      setError(err.response?.data || 'Registration failed');
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify2FA = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const verificationPayload = {
        username,
        email,
        password,
        wallet,
        currency: currency.value,
        twoFaMethod,
        twoFaCode,
        twoFaSecret,
      };

      const response = await axios.post('/api/verify-2fa', verificationPayload);
      localStorage.setItem('token', response.data.accessToken); // Ensure the token is stored correctly
      gtag('event', 'register', {
        'event_category': 'User',
        'event_label': 'Registration_Completed',
        'value': 1
      });
      setIsLoading(false);
      setError(''); // Clear any error message
      setSuccessMessage('2FA verification successful!');
      onLogin(response.data);
    } catch (err) {
      setIsLoading(false);
      gtag('event', 'register', {
        'event_category': 'User',
        'event_label': 'Registration_Error',
        'value': 1,
        'event_action': err.response?.data || 'Registration Verification failed'
      });
      setSuccessMessage('');
      setError(err.response?.data || 'Verification failed');
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post('/api/forgot-password', { email });
      setError('');
      setSuccessMessage('If an account with that email exists, a reset link will be sent.');
      setIsLoading(false);
    } catch (err) {
      setError('Error sending reset email. Please try again.');
      setSuccessMessage('');
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(twoFaSecret);
    setCopied(true);
    setTimeout(() => setCopied(false), 30000);
  }

  return (
      <div className="register-login-page">
        <LoadingModal isOpen={isLoading} />
        {isLoginMode && !is2FAModalOpen && !forgotPasswordMode && (
          <>
            <h2>LOGIN</h2>
            {error && <p className="message error">{error}</p>}
            {successMessage && <p className="message success">{successMessage}</p>}
            <form onSubmit={handleInitialLogin}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="password-wrapper">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder=""
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <img
                    src={showPassword ? visibleImg : invisibleImg}
                    alt="Toggle visibility"
                    className="toggle-password"
                    onClick={toggleShowPassword}
                  />
                </div>
              </div>
              <div class="button-container">
                <button type="submit" className="btn-login">Login</button>
              </div>
            </form>
            <p>
              <button onClick={() => setForgotPasswordMode(true)} className="forgot-password-link">
                Forgot Password?
              </button>
            </p>
            <p>
              Don't have an account? <button onClick={() => { setIsLoginMode(false); setError(''); }} className="login-link">Register here</button>
            </p>
            </>
          )}
          {forgotPasswordMode && (
          <>
            <h2>Forgot Password</h2>
            {error && <p className="message error">{error}</p>}
            {successMessage && <p className="message success">{successMessage}</p>}
            <form onSubmit={handleForgotPassword}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div class="button-container">
                <button type="submit" className="btn-login">Send Reset Link</button>
              </div>
            </form>
            <p>
              Remember your password? <button onClick={() => setForgotPasswordMode(false)} className="login-link">Login here</button>
            </p>
          </>
        )}
          {is2FAModalOpen && (
            <div className="form-group centered two-fa-input-form">
              <h2>Enter 2FA Code</h2>
              {error && <p className="message error">{error}</p>}
              {successMessage && <p className="message success">{successMessage}</p>}
              <form onSubmit={handleVerify2FALogin}>
              <div className="form-group">
                <label>2FA Code</label>
                <input
                  type="text"
                  placeholder=""
                  value={twoFaCode}
                  onChange={(e) => setTwoFaCode(e.target.value)}
                  required
                />
                </div>
                <div class="button-container">
                  <button type="submit" className="btn-login">Verify</button>
                </div>
              </form>
              </div>
            )}
          {!isLoginMode && !is2FAModalOpen && (
        <>
          <h2>REGISTER</h2>
          {error && <p className="message error">{error}</p>}
          {successMessage && <p className="message success">{successMessage}</p>}
          <form onSubmit={qrCode || twoFaCodeSent ? handleVerify2FA : handleRegister}>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                placeholder=""
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="password-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <img
                  src={showPassword ? visibleImg : invisibleImg}
                  alt="Toggle visibility"
                  className="toggle-password"
                  onClick={toggleShowPassword}
                />
              </div>
              <div className="password-strength">
                <div className={`strength-meter strength-${passwordStrength}`} />
                <p className="strength-text">
                  {passwordStrength === 0 && 'Very Weak'}
                  {passwordStrength === 1 && 'Weak'}
                  {passwordStrength === 2 && 'Fair'}
                  {passwordStrength === 3 && 'Good'}
                  {passwordStrength === 4 && 'Strong'}
                </p>
              </div>
            </div>
            <div className="form-group">
              <label>Currency</label>
              <Select
                classNamePrefix="react-select"
                value={currency}
                onChange={(selected) => setCurrency(selected)}
                options={currencies}
                placeholder="Select Currency"
              />
            </div>
            <div className="form-group">
              <label>Wallet Address</label>
              <input
                type="text"
                placeholder=""
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Two-Factor Authentication (2FA)</label>
              <select value={twoFaMethod} onChange={(e) => setTwoFaMethod(e.target.value)}>
                <option value="none">None</option>
                <option value="app">Authenticator App</option>
                <option value="email">Email</option>
              </select>
            </div>

            {qrCode && (
              <div className="form-group">
                <center>
                  <label>Scan this QR Code or copy the secret key to your Authenticator App</label>
                  <img src={qrCode} alt="QR Code" />
                </center>
                <div className="copy-section">
                  <button className="copy-button" onClick={copyToClipboard}>
                    {copied ? 'Copied!' : 'Copy Secret Key'}
                  </button>
                </div>
              </div>
            )}

            {(qrCode || twoFaCodeSent) && (
              <div className="form-group">
                <label>Enter the code from your app/email</label>
                <input
                  type="text"
                  placeholder=""
                  value={twoFaCode}
                  onChange={(e) => setTwoFaCode(e.target.value)}
                  required
                />
              </div>
            )}
            <div class="button-container">
              <button type="submit" className="btn-login">
                {qrCode || twoFaCodeSent ? 'Verify 2FA Code' : 'Register'}
              </button>
            </div>
          </form>
          <p>
            Already have an account? <button onClick={() => { setIsLoginMode(true); setError(''); }} className="login-link">Login here</button>
          </p>
        </>
      )}
      <img src={registerLoginImage} alt="Login or Register to WinCoinDraw now to buy tickets for our next draw." className="register-login-image" />
    </div>
  );
};

export default RegisterLoginPage;
