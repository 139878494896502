/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TwoFAModal.css';

const TwoFAModal = ({ isOpen, onClose, email, twoFaMethod, setIsLoading }) => {
  const [twoFaMethodState, setTwoFaMethodState] = useState(twoFaMethod || 'none');
  const [twoFaCode, setTwoFaCode] = useState('');
  const [newTwoFaMethod, setNewTwoFaMethod] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [twoFaSecret, setTwoFaSecret] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(true);
  const [verifyButtonVisible, setVerifyButtonVisible] = useState(false);
  const [closeButtonText, setCloseButtonText] = useState('Cancel');
  const [successFa, setSuccessFa] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Set the dropdown to the current 2FA method when the modal opens
    setTwoFaMethodState(twoFaMethod || 'none');
    setSaveButtonVisible(false); // Hide Save button initially
  }, [isOpen, twoFaMethod]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Please log in.');
        setSaveButtonVisible(false);
        setCloseButtonText('Close');
        setIsLoading(false);
        return;
      }

      const response = await axios.post('/api/update-2fa-method', {
        email: email,
        newTwoFaMethod: twoFaMethodState
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setTwoFaSecret(response.data.twoFaSecret);

      if (twoFaMethodState === 'app') {
        setQrCode(response.data.qrCode);
        setMessage('Scan the QR code or copy the secret key, then enter the code from your authenticator app.');
      } else if (twoFaMethodState === 'email') {
        setMessage(response.data.message);
      } else if (twoFaMethodState === 'none') {
        setMessage('2FA has been disabled. For security purposes, we recommend using 2FA.');
        setSuccessFa(true);
        setCloseButtonText('Close');
      }

      setError('');
      setNewTwoFaMethod(twoFaMethodState);
      setSaveButtonVisible(false);

      if (twoFaMethodState !== 'none') {
        setVerifyButtonVisible(true);
      }

      // Track 2FA method update event
      if (window.gtag) {
        const eventLabel = twoFaMethodState === 'none' ? '2FA Disabled' : `2FA Method Updated to ${twoFaMethodState}`;
        window.gtag('event', '2fa_method_updated', {
          'event_category': 'Security',
          'event_label': eventLabel,
          'value': 1,
        });
      }
    } catch (error) {
      setError('Error updating 2FA method. Please try again.');
      setMessage('');
      setSaveButtonVisible(true);
      if (window.gtag) {
        window.gtag('event', '2fa_verification_failure', {
          'event_category': 'Security',
          'event_label': '2FA Verification Failed',
          'value': 1,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async () => {
    setIsLoading(true);
    setMessage('');
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Please log in.');
      setSaveButtonVisible(false);  // Hide the Save button
      setCloseButtonText('Close');
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post('/api/verify-2fa-update', {
        email: email,
        twoFaMethod: newTwoFaMethod,
        twoFaCode: twoFaCode,
        twoFaSecret: twoFaSecret
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.message === '2FA method updated successfully.') {
        setMessage(response.data.message);
        setSuccessFa(true);
        setSaveButtonVisible(false);  // Hide the Save button after success
        setVerifyButtonVisible(false);  // Hide the Verify button
        setCloseButtonText('Close');  // Change Cancel button to Close

        // Track 2FA verification success event
        if (window.gtag) {
          window.gtag('event', '2fa_verification_success', {
            'event_category': 'Security',
            'event_label': '2FA Verified Successfully',
            'value': 1,
          });
        }
      } else {
        setError('Invalid 2FA code. Please try again.');

        // Track 2FA verification failure event
        if (window.gtag) {
          window.gtag('event', '2fa_verification_failure', {
            'event_category': 'Security',
            'event_label': '2FA Verification Failed',
            'value': 1,
          });
        }
      }
    } catch (error) {
      setError('Error verifying 2FA code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setError('');
    setMessage('');
    setQrCode('');
    setTwoFaCode('');
    setTwoFaMethodState('none');
    setVerifyButtonVisible(false);
    setSaveButtonVisible(true);

    if (successFa) {
      onClose(newTwoFaMethod);
    } else {
      onClose();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(twoFaSecret)
        .then(() => setCopied(true))
        .catch(() => setCopied(false));

    // Reset copied state after 30 seconds
    setTimeout(() => setCopied(false), 30000);
  };

  if (!isOpen) return null;

  return (
    <div className="two-fa-modal-overlay">
      <div className="two-fa-modal">
        <h3>Manage Two-Factor Authentication</h3>
        <div className="form-group">
          <label>Select 2FA Method:</label>
          <select
            value={twoFaMethodState}
            onChange={(e) => {
              setTwoFaMethodState(e.target.value);
              setSaveButtonVisible(e.target.value !== twoFaMethod); // Hide Save button if not changed
              setVerifyButtonVisible(false);  // Hide the Verify button until Save is clicked
              setQrCode('');  // Clear QR code
              setTwoFaCode('');  // Clear 2FA code input
              setMessage('');  // Clear any previous messages
              setError('');  // Clear any previous errors
            }}
          >
            <option value="none">Disable</option>
            <option value="app">Authenticator App</option>
            <option value="email">Email</option>
          </select>
        </div>

        {qrCode && (
          <div className="qr-section">
            <img src={qrCode} alt="QR Code for 2FA" />
            <div className="copy-section">
              <button className="copy-button" onClick={copyToClipboard}>
                {copied ? 'Copied!' : 'Copy Secret Key'}
              </button>
            </div>
          </div>
        )}

        {verifyButtonVisible && (
          <div className="form-group">
            <label>Enter the code:</label>
            <input
              type="text"
              value={twoFaCode}
              onChange={(e) => setTwoFaCode(e.target.value)}
            />
          </div>
        )}

        {saveButtonVisible && (
          <button onClick={handleSave} className="btn-save">Save</button>
        )}
        {verifyButtonVisible && (
          <button onClick={handleVerify} className="btn-verify">Verify</button>
        )}
        {error && <p className="error">{error}</p>}
        {message && <p className="success">{message}</p>}
        <button onClick={handleClose} className="btn-close">{closeButtonText}</button>
      </div>
    </div>
  );
};

export default TwoFAModal;
