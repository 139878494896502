/* global gtag */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import LoadingModal from './LoadingModal';
import './BuyTicketsPage.css';
import bundlesImage from '../assets/images/bundlesImage.svg';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#010e1c',
    color: '#fff',
    border: '1px solid #1db5cb',
    boxShadow: 'none', // remove box-shadow
    outline: 'none',   // remove outline
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#fff',
  }),
};

const BuyTicketsPage = () => {
  const [numTickets, setNumTickets] = useState(1);
  const [totalTickets, setTotalTickets] = useState(1);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [ticketNumbers, setTicketNumbers] = useState([]);
  const [random, setRandom] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState('');
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [minPaymentAmount, setMinPaymentAmount] = useState(null);
  const [minPaymentError, setMinPaymentError] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('Awaiting payment confirmation...');
  const [minTickets, setMinTickets] = useState(1);
  const [paymentStatusInterval, setPaymentStatusInterval] = useState(null);
  const [drawDate, setDrawDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const cryptoNote = "Due to the nature of cryptocurrency transactions, a minimum ticket purchase is required for each payment method.";

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.post('/api/user', {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setIsLoggedIn(true);
        } catch (err) {
          localStorage.removeItem('token');
          setIsLoggedIn(false);
          setShowLoginModal(true);
        }
      } else {
        setIsLoggedIn(false);
        setShowLoginModal(true);
      }
    };

    validateToken();
  }, []);

  useEffect(() => {
    const fetchSupportedCurrencies = async () => {
      try {
        const response = await axios.get('/api/supported-currencies');
        const currencies = response.data.supportedCurrencies
        .map(curr => ({ value: curr, label: curr.toUpperCase() }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically

        setSupportedCurrencies([{ value: '', label: 'Select Currency' }, ...currencies]); // Add blank option
      } catch (err) {
        setError('Failed to fetch supported currencies', err);
      }
    };

    fetchSupportedCurrencies();
  }, []);

  useEffect(() => {
    const currencies = supportedCurrencies.map((curr) => ({
      value: curr,
      label: curr.toUpperCase(),
    })).sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically

    setSupportedCurrencies([{ value: '', label: 'Select Currency' }, ...currencies]);
  }, []);

  useEffect(() => {
    setDrawDate(getNextSunday());
  }, []);

  const getNextSunday = () => {
    const today = new Date();
    const currentHour = today.getUTCHours();
    const currentMinute = today.getUTCMinutes();

    let nextSunday = new Date(today);

    // If today is Sunday and before 17:00 UTC, use today as the draw date
    if (today.getUTCDay() === 0 && (currentHour < 17 || (currentHour === 17 && currentMinute < 1))) {
      nextSunday.setUTCHours(17, 0, 0, 0);
    } else {
      // Otherwise, calculate the next Sunday
      nextSunday.setUTCDate(today.getUTCDate() + (7 - today.getUTCDay()) + (today.getUTCDay() === 0 ? 7 : 0));
      nextSunday.setUTCHours(17, 0, 0, 0);
    }

    return nextSunday.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  };

  // formatting drawDate
  const formattedDrawDate = new Date(drawDate);
    formattedDrawDate.setUTCHours(17, 0, 0, 0);
    const formattedDateString = formattedDrawDate.toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short'
    });

    const handleTicketChange = (e) => {
      const inputTickets = e.target.value;

      // Allow the user to type freely, but limit the value between 1 and 1000
      if (inputTickets === '' || (parseInt(inputTickets, 10) >= 1 && parseInt(inputTickets, 10) <= 1000)) {
        setNumTickets(inputTickets);

        // Update total tickets and ticket numbers based on the input
        const { totalTickets } = calculateTotalTickets(inputTickets);
        setTotalTickets(totalTickets);
        setTicketNumbers(Array(totalTickets).fill('').map(() => random ? generateRandomTicketNumber() : ''));
      }
    };


    const handleTicketBlur = () => {
      let inputTickets = parseInt(numTickets, 10);

      // Enforce minimum and maximum limits on blur
      if (isNaN(inputTickets) || inputTickets < minTickets) {
        inputTickets = minTickets;
      } else if (inputTickets > 1000) {
        inputTickets = 1000;
      }

      setNumTickets(inputTickets);

      // Update total tickets and ticket numbers based on the valid input
      const { totalTickets } = calculateTotalTickets(inputTickets);
      setTotalTickets(totalTickets);
      setTicketNumbers(Array(totalTickets).fill('').map(() => random ? generateRandomTicketNumber() : ''));
    };

  const handleCurrencyChange = async (selectedOption) => {
    setIsLoading(true);
    setSelectedCurrency(selectedOption.value);

    try {
      const response = await axios.get('/api/calculate-min-tickets', {
        params: {
          currency_from: selectedOption.value,
          currency_to: 'usdttrc20'
        }
      });

      const { minTickets, minAmount, roundedMinAmountInUSDT } = response.data;

      //console.log("API Response:", response.data); // Debugging: log API response

      setMinPaymentAmount(minAmount); // Set minimum payment amount in the selected currency
      setMinPaymentError('');

      // Only update the number of tickets if it's less than the minimum required
      if (numTickets < minTickets) {
        setNumTickets(minTickets);
        setMinTickets(minTickets);

        // Calculate total tickets based on the minimum tickets
        const { totalTickets } = calculateTotalTickets(minTickets);
        setTotalTickets(totalTickets);
        setTicketNumbers(Array(totalTickets).fill('').map(() => random ? generateRandomTicketNumber() : ''));
      }
      setIsLoading(false);
    } catch (error) {
      //console.error('Failed to fetch minimum payment amount', error);
      setMinPaymentAmount(null);
      setMinPaymentError('Failed to fetch minimum payment amount. Please try again.');
      setIsLoading(false);
    }
  };

  // Function to reset the form
  const resetForm = () => {
    setNumTickets(1);
    setTotalTickets(1);
    setSelectedCurrency('');
    setTicketNumbers([]);
    setRandom(false);
    setMinPaymentAmount(null);
    setPaymentUrl('');
    setPaymentStatus('Awaiting payment confirmation...');
    setShowModal(false);
    setError('');
    window.location.reload();
  };

  const checkPaymentStatus = async (invoiceId) => {
    try {
      const response = await axios.get('/api/check-payment-status', {
        params: { invoiceId }
      });

      if (!response || !response.data) {
        throw new Error('No response data from server');
      }

      const { status } = response.data;

      if (status === 'Finished') {
        setPaymentConfirmed(true);
        setConfirmationMessage('Payment confirmed. Your tickets have been purchased successfully!');
        setPaymentStatus('Payment confirmed');

        // Track successful purchase event in Google Analytics
        if (window.gtag) {
          window.gtag('event', 'purchase', {
            'event_category': 'Ecommerce',
            'event_label': 'Ticket Purchase Successful',
            'value': minPaymentAmount, // The amount spent in the selected currency
            'currency': 'USD', // The currency used
            'items': [
              {
                'item_name': 'Lottery Tickets',
                'quantity': totalTickets, // Number of tickets purchased
              }
            ]
          });
        }
      } else if (status === 'Pending') {
        setPaymentStatus('Awaiting payment confirmation...');
        setTimeout(() => checkPaymentStatus(invoiceId), 30000); // Retry after 30 seconds
      } else if (status === 'Failed' || status === 'Expired') {
        setPaymentStatus(`Payment ${status}. Please try again.`);
        setError(`Payment ${status}. Please try again.`);
      } else {
        setPaymentStatus(`Payment status: ${status}`);
        setTimeout(() => checkPaymentStatus(invoiceId), 30000); // Retry after 30 seconds
      }
    } catch (error) {
      //console.error('Error checking payment status:', error.message || error);
      setError('Error checking payment status. Please try again.');
    }
  };

  const openPaymentPage = () => {
    window.open(paymentUrl, '_blank');
  };


  const handlePayment = async () => {
    setError('');

    if (!selectedCurrency) {
        setError('Please select a payment currency.');
        return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
        setError('User is not authenticated. Please log in.');
        return;
    }

    setIsLoading(true);
    const drawDate = getNextSunday(); // Calculate the correct draw date

    const requestBody = {
        num_tickets: numTickets,
        ticket_numbers: ticketNumbers.map(ticket => ticket.number).join(','), // Ensure ticket numbers are correctly joined
        protocol: selectedCurrency,
        draw_date: drawDate
    };

    //console.log('Request body:', requestBody);

    try {
        const response = await axios.post('/api/buy-tickets', requestBody, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response || !response.data) {
            setIsLoading(false);
            throw new Error('No response data from server');
        }

        const { payment_url, invoice_id } = response.data;

        if (!payment_url || !invoice_id) {
            setIsLoading(false);
            throw new Error('Incomplete response data from server');
        }

        let paymentUrl = payment_url;
        if (paymentUrl.startsWith("http://")) {
            paymentUrl = paymentUrl.replace("http://", "https://");
        }

        setPaymentUrl(paymentUrl);
        setShowModal(true);
        setIsLoading(false);

        // Track payment initiation event in Google Analytics
        if (window.gtag) {
          window.gtag('event', 'begin_checkout', {
            'event_category': 'Ecommerce',
            'event_label': 'Ticket Purchase Initiated',
            'value': minPaymentAmount, // The amount set as minimum payment
            'currency': 'USD', // The currency selected
            'items': [
              {
                'item_name': 'Lottery Tickets',
                'quantity': totalTickets, // Number of tickets being purchased
              }
            ]
          });
        }

        // Start checking for payment status
        checkPaymentStatus(invoice_id);
    } catch (err) {
        setIsLoading(false);
        //console.error('Payment failed:', err.message || err);
        setError('Payment failed. ' + err.response.data.error);


        // Track payment failure event in Google Analytics
        if (window.gtag) {
          window.gtag('event', 'exception', {
            'description': 'Payment Failed',
            'fatal': false,
            'event_category': 'Ecommerce',
            'event_label': 'Ticket Purchase Failed',
          });
        }

        if (err.response) {
            //console.error('Response data:', err.response.data);
            //console.error('Response status:', err.response.status);
            //console.error('Response headers:', err.response.headers);
        } else if (err.request) {
            //console.error('Request data:', err.request);
        } else {
            //console.error('Error message:', err.message);
        }
    }
  };


  const calculateTotalTickets = (numTickets) => {
    const bundleTiers = [
      { threshold: 40, bonus: 10 },
      { threshold: 25, bonus: 5 },
      { threshold: 10, bonus: 2 },
      { threshold: 5, bonus: 1 },
    ];

    // Ensure at least the minimum number of tickets is selected
    let remainingTickets = Math.max(parseInt(numTickets, 10) || 0, 1);
    //console.log("Remaining Tickets (after max check):", remainingTickets); // Debugging
    let totalTickets = remainingTickets;

    for (const tier of bundleTiers) {
      if (remainingTickets >= tier.threshold) {
        const groups = Math.floor(remainingTickets / tier.threshold);
        totalTickets += groups * tier.bonus;
        remainingTickets -= groups * tier.threshold;
      }
    }

    //console.log("Calculated Total Tickets:", totalTickets); // Debugging
    return { price: numTickets, totalTickets };
  };

  const generateRandomTicketNumber = () => {
    let ticketNumber = '';
    for (let i = 0; i < 6; i++) {
      const randomNum = Math.floor(Math.random() * 100).toString().padStart(2, '0');
      ticketNumber += `${randomNum} `;
    }
    return ticketNumber.trim();
  };

  const handleSelectAllRandomChange = (value) => {
    setRandom(value);
    const newTicketNumbers = Array.from({ length: totalTickets }).map(() => value ? { number: generateRandomTicketNumber(), random: true } : { number: '', random: false });
    setTicketNumbers(newTicketNumbers);
  };

  const handleRandomChange = (index, value) => {
    const newTicketNumbers = [...ticketNumbers];
    newTicketNumbers[index] = value ? { number: generateRandomTicketNumber(), random: true } : { ...newTicketNumbers[index], random: false };
    setTicketNumbers(newTicketNumbers);
  };

  const getFriendlyPaymentStatusMessage = (status) => {
    switch (status) {
      case 'Awaiting payment confirmation...':
        return 'Awaiting payment confirmation...';
      case 'Waiting':
        return 'We are currently waiting for your payment to be sent. Please complete the payment to continue.';
      case 'Confirming':
        return 'Your payment is being processed on the blockchain. This may take a few moments, depending on network confirmations.';
      case 'Confirmed':
        return 'Your payment has been confirmed! We\'re now processing the transaction on our end.';
      case 'Sending':
        return 'We\'re sending your funds to your wallet. Please hold on while we finalize the process.';
      case 'Partially_paid':
        return 'It looks like the amount you sent was less than required. You can either send the remaining amount or contact support for assistance. Please keep this window open.';
      case 'Finished':
        return 'Your payment was successful, and the funds have reached your wallet. Thank you for your purchase!';
      case 'Failed':
        return 'There was an issue with your payment. Please try again or contact support for help.';
      case 'Expired':
        return 'Your payment session has expired. Please start a new transaction if you still wish to proceed.';
      default:
        return 'An unknown error occurred. Please try again later.';
    }
  };

  return (
    <div className="buy-tickets-page">
      <LoadingModal isOpen={isLoading} />
      <div className="bundles-header">
        <img src={bundlesImage} className="bundles-image" alt="WinCoinDraw Bundles: Buy 5 get 1 free, Buy 10 get 2 free, Buy 25 get 5 free, Buy 40 get 10 free" />
      </div>
      <p className="crypto-note">{cryptoNote}</p>

      <div className="ticket-purchase">
          <div className="form-group">
            <label htmlFor="paymentCurrency">Payment Currency</label>
            <Select
              classNamePrefix="custom-select"
              id="paymentCurrency"
              styles={customStyles}
              value={supportedCurrencies.find(curr => curr.value === selectedCurrency)}
              onChange={handleCurrencyChange}
              options={supportedCurrencies}
              placeholder="Select Currency"
            />
            {minPaymentError && <p className="error">{minPaymentError}</p>}
            {minPaymentAmount && (
              <p className="min-payment-info">
                Minimum payment amount: {minPaymentAmount.toFixed(8)} {selectedCurrency.toUpperCase()}
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="ticketCount">Number of Tickets</label>
            <input
              type="text"
              id="ticketCount"
              value={numTickets}
              onChange={handleTicketChange}
              onBlur={handleTicketBlur}
              placeholder="Enter number of tickets"
              pattern="[0-9]*"
              maxLength="4"
              required
            />
            <p>Total Tickets (including free): {totalTickets}</p>
          </div>
          <div className="form-group">
            <label htmlFor="drawDate">Draw Date</label>
            <input
              type="text"
              id="drawDate"
              value={formattedDateString}
              readOnly
            />
          </div>
          <div className="ticket-number-section">
            <div className="ticket-number-random">
              <label>
                <input
                  type="checkbox"
                  checked={random}
                  onChange={(e) => handleSelectAllRandomChange(e.target.checked)}
                />
                &nbsp;Generate Random Numbers
              </label>
            </div>
            <div className="ticket-numbers-table">
              <table>
                <thead>
                  <tr>
                    <th>Ticket Number</th>
                    <th>Random</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: totalTickets }).map((_, index) => (
                    <tr key={index}>
                      <td>
                        <div className="ticket-number-inputs">
                          {Array.from({ length: 6 }).map((_, numIndex) => (
                            <input
                              key={numIndex}
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              min="0"
                              max="99"
                              value={ticketNumbers[index]?.number?.split(' ')[numIndex] || ''}
                              onChange={(e) => {
                                const newTicketNumbers = [...ticketNumbers];
                                const ticketParts = newTicketNumbers[index]?.number?.split(' ') || [];
                                ticketParts[numIndex] = e.target.value;
                                newTicketNumbers[index] = { ...newTicketNumbers[index], number: ticketParts.join(' ') };
                                setTicketNumbers(newTicketNumbers);
                              }}
                              disabled={random || ticketNumbers[index]?.random}
                              maxLength="2"
                            />
                          ))}
                        </div>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={random || ticketNumbers[index]?.random}
                          onChange={(e) => handleRandomChange(index, e.target.checked)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <button className="button" onClick={handlePayment}>Buy Tickets</button>
      </div>
      {error && <div className="error">{error}</div>}
      {showModal && (
        <div className="payment-modal">
          <div className="modal-content">
            <h2>Payment</h2>
            {!paymentConfirmed ? (
              <>
                <p>Please complete your payment by clicking the button below to open the payment page.</p>
                <button className="payment-button" onClick={openPaymentPage}>Open Payment Page</button>
                <p>{getFriendlyPaymentStatusMessage(paymentStatus)}</p> {/* Show the live status */}
                <p>We are checking for your payment status. This may take a few minutes...</p>
                <button className="button" onClick={() => setShowModal(false)}>Close</button>
              </>
            ) : (
              <>
                <p>{confirmationMessage}</p>
                <button className="button" onClick={() => window.location.href = '/dashboard'}>Go to Dashboard</button>
                <button className="button" onClick={() => window.location.href = '/'}>Go to Home</button>
                <button className="button" onClick={() => resetForm()}>Close</button>
              </>
            )}
          </div>
        </div>
      )}
      {showLoginModal && (
        <div className="login-modal">
          <div className="modal-content">
            <h2>Please log in</h2>
            <p>You need to be logged in to buy tickets.</p>
            <button className="button" onClick={() => window.location.href = '/register-login'}>Go to Login</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyTicketsPage;
