import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useNavigate, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import RegisterLoginPage from './pages/RegisterLoginPage';
import Dashboard from './pages/Dashboard';
import BuyTicketsPage from './pages/BuyTicketsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import NotFoundPage from './pages/NotFoundPage';
import logo from './assets/images/WINCOINDRAW_logo.svg';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import FacebookPixel from 'react-facebook-pixel';

const tagManagerArgs = {
  gtmId: 'G-39710SD9EK' // Replace with your Google Tag Manager ID
};

TagManager.initialize(tagManagerArgs);

const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

// initialize the pixel
FacebookPixel.init(pixelId);
FacebookPixel.pageView(); // Track page view when user visits the page

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.post('/api/user', {}, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setIsLoggedIn(true);
        } catch (err) {
          localStorage.removeItem('token');
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    validateToken();
  }, [isLoggedIn]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMenuOpen && !event.target.closest('.navbar-menu') && !event.target.closest('.navbar-toggle')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Track page view on route change
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname,
      title: document.title,
    });
  }, [location]);

  const handleLogin = (data) => {
    localStorage.setItem('token', data.accessToken);
    setIsLoggedIn(true);
    navigate('/dashboard'); // Navigate to dashboard or appropriate page after login
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="app">
      <nav className="navbar">
        <div className="navbar-toggle" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <div className="navbar-logo">
          <NavLink to="/" onClick={closeMenu}><img src={logo} alt="WinCoinDraw Logo" /></NavLink>
        </div>
        <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
          <button className="navbar-close" onClick={closeMenu}>
            <i className="fas fa-times"></i>
          </button>
          <ul>
            <li><NavLink to="/" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink></li>
            <li><NavLink to="/buy-tickets" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Buy Tickets</NavLink></li>
            <li><NavLink to="/about" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>About Us</NavLink></li>
            <li><NavLink to="/contact" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Contact Us</NavLink></li>
            {isLoggedIn ? (
              <>
                <li><NavLink to="/dashboard" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Dashboard</NavLink></li>
                <li><NavLink to="/" onClick={handleLogout} className="">Logout</NavLink></li>
              </>
            ) : (
              <li><NavLink to="/register-login" onClick={closeMenu} className={({ isActive }) => (isActive ? 'active' : '')}>Register/Login</NavLink></li>
            )}
          </ul>
        </div>
      </nav>
      <div className="app-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/register-login" element={<RegisterLoginPage onLogin={handleLogin} />} />
          <Route path="/dashboard" element={<Dashboard setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/buy-tickets" element={<BuyTicketsPage setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <footer className="app-footer">
        <div className="footer-content">
          <ul className="footer-links">
            <li><a href="/privacy">Privacy Policy</a></li>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/faqs">FAQs</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
          <div className="footer-social">
            <a href="https://www.facebook.com/Wincoindraw" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.x.com/wincoindraw" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/wincoindraw" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@wincoindraw" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://www.youtube.com/@wincoindraw" className="social-icon" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 WinCoinDraw. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
